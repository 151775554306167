<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Expedientes abiertos
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Listado de expedientes actualmente en proceso
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="abiertos"
              :options="options"
              height="300px"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="resumenCerrados"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="info"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Expedientes gestionados
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Resumen de expedientes procesados por meses
          </p>
        </base-material-chart-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ExpedientesApi from '@/services/api/Expedientes'

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        options: {
          itemsPerPage: 5,
        },
        resumenCerrados: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          series: [],
        },
        emailsSubscriptionChart: {
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 10,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 840px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: false,
            text: 'Cliente',
            value: 'clienteName',
          },
          {
            sortable: false,
            text: 'Procedimiento',
            value: 'procedimiento.nombre',
          },
          {
            sortable: false,
            text: 'Protocolo',
            value: 'protocolo',
          },
        ],
        abiertos: [],
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchExpedientesAbiertos () {
        ExpedientesApi.getAllExpedientesAbiertos()
          .then(abiertos => {
            this.abiertos = abiertos
          })
          .catch(error => console.log(error))
      },
      fetchExpedientesCerradosPorMes () {
        ExpedientesApi.getAllExpedientesCerradosPorMes()
          .then(cerrados => {
            this.resumenCerrados.series.push(cerrados)
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchExpedientesAbiertos()
        this.fetchExpedientesCerradosPorMes()
      },
    },
  }
</script>
